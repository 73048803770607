<template>
    <div class="mct-modulos container">
        <div class="row">
            <div class="col" v-for="(modulo,index) in data" :key="index" @click="abrirModulo($event,modulo)" :style="'background:'+modulo.mod_cor">

                <h1 class="mct-text">{{ modulo.mod_descricao }}</h1>
                <img :src="'src/assets/'+modulo.mod_imagem">
                <p class="mct-text">{{ modulo.mod_desclonga }}</p>

            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import LayoutDefault from '@/components/layouts/LayoutDefault.vue';

export default {
    data (){
        return {
            data: {}
        }
    },
    methods: {
        retornaLista () {
            var vm = this
            axios.get(`/modulos/lista`)
            .then(function(response) {
                Vue.set(vm.$data, 'data', response.data.data)
                if (vm.$data.data.length === 1){
                    vm.abrirModulo(null,vm.$data.data[0])
                }
            })
            .catch(function(response) {
                console.log(response.message)
            })
        },
        abrirModulo (ev, modulo) {
            if (modulo.submodulo.length == 0 && modulo.mod_codigo != 0 && modulo.mod_codigo != null && modulo.mod_codigo != 'undefined')
            {
                this.$store.dispatch('Login/USU_MODULO', modulo);                
                this.$store.dispatch('Login/MENU');
                this.$store.dispatch('Login/LAYOUT',LayoutDefault);
                this.$router.push({path: '/'})
            }else
            if (modulo.submodulo.length > 0 && modulo.mod_codigo != 0 && modulo.mod_codigo != null && modulo.mod_codigo != 'undefined')
            {
                this.$store.dispatch('Login/USU_MODULO', modulo);
                this.$router.push({path: '/submodulos'})
            }
        },
    },
    mounted() {
        this.retornaLista()
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=PT+Sans');

    .mct-modulos * {
        font-family: 'PT Sans', Helvetica, Arial, Verdana, sans-serif;
    }

    .mct-modulos{
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
    }

    .mct-modulos h1{
        color: #ffffff;
        font-size: 1.6em;
        font-weight: bold;
    }

    .mct-modulos p{
        width: 100%;
        color: #ffffff;
        font-size: 0.7em;
        font-weight: normal;
        position: absolute;
        bottom: 0;
    }

    .mct-modulos img{
        max-height: 100px;
    }

    .mct-modulos .col{
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        background-color: red;
        /*margin: 5px;*/
        padding: 2px;
        overflow: hidden;

        position: relative;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);
    }

    .mct-modulos .col:hover {
        box-shadow: 1px 1px 1px #FFFFFF;
        z-index: 9999;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.2);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.2);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.2);
        transition: all 200ms ease-in;
        transform: scale(1.2);
    }

    .row {
        margin: 0 auto;
    }

</style>
